export const PATHS = {
  home: '/',
  services: '/services',
  aboutUs: '/about-us',
  portfolio: '/portfolio'
};

export const PATHS_EN = {
  home: '/en',
  services: '/en/services',
  aboutUs: '/en/about-us',
  portfolio: '/en/portfolio'
};
