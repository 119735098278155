export default {
  translations: {
    navBar: {
      route1: 'Serviços',
      route2: 'Sobre nós',
      route3: 'Portfolio'
    },
    services: {
      hero: {
        title: 'Tudo o que precisa num só lugar',
        description1: 'Oferecemos uma experiência completa, desde a concepção até à implementação, garantindo excelência em cada etapa.',
        description2: 'Todos os serviços de instalação são executados pela nossa experiente e dedicada equipa da i9atek.',
        action: '‣ Saiba mais'
      },
      main: {
        title1: 'cinema',
        desc1: 'Soluções exclusivas, onde a aliança entre o design e a tecnologia, resulta em salas excecionais.',
        left1: ['Imagem 4k & 8k', 'Correção acústica'],
        right1: ['Som Dolby Atmos', 'Poltronas personalizadas'],
        title2: 'segurança',
        desc2: 'A mais recente tecnologia para o ajudar a proteger o que lhe é mais importante.',
        left2: ['Vídeo vigilância', 'Controlo de acessos'],
        right2: ['Alarmes de instrusão', 'Cofre e sala de pânico'],
        title3: 'domótica',
        desc3: 'Casas inteligentes que lhe proporcionam conforto, economia e bem-estar.',
        left3: ['Controlo de Iluminação', 'Controlo de temperatura'],
        right3: ['Controlo de estores/cortinas', 'Integração com Multimédia e Segurança'],
        title4: 'som',
        desc4: 'Áudio interior e exterior que acrescentam comodidade com um simples toque no seu tablet ou telemóvel.',
        left4: ['Som Multi-Room', 'Som imersivo'],
        right4: ['Alta-ﬁdelidade', 'Som invisível'],
        title5: 'redes',
        desc5: 'Conecte dispositivos dentro e fora de casa, facilitando o compartilhamento de internet e recursos.',
        left5: ['Proteção Anti-Hacker', 'Rede Estruturada'],
        right5: ['Cobertura Wi-Fi Perfeita', 'Integração de equipamentos'],
        title6: 'eletricidade',
        desc6: 'O princípio de tudo, instalações elétricas projetadas para espaços diferenciados.',
        left6: ['Projetos Elétricos', 'Instaladores certiﬁcados'],
        right6: ['Projetos Domótica', 'Instalações especiais']
      },
      craft: {
        title: 'Feito do zero',
        description1:
          'Projetamos e personalizamos meticulosamente cada projeto de acordo com as suas especificações exatas, garantindo uma criação única e personalizada só para si.',
        subTitle: 'Uma equipa certificada',
        description2: 'Procuramos continuamente a melhor tecnologia para nos mantermos na vanguarda da indústria.',
        label1: 'Contacto',
        label2: 'Avaliação',
        label3: 'Desenvolvimento',
        label4: 'Proposta',
        label5: 'Aceitação',
        label6: 'Preparação',
        label7: 'Execução',
        label8: 'Testes gerais',
        label9: 'Acompanhamento'
      }
    },
    aboutUs: {
      hero: {
        title: 'Create to innovate',
        description: 'O Nosso objetivo é unir design e tecnologia para criar espaços onde se sinta realmente em casa.'
      },
      creation: {
        title: 'O princípio',
        description1: 'A F4build foi criada para se tornar referência nacional e internacional na área da inovação tecnológica residencial.',
        description2:
          'O nosso compromisso é procurar as mais recentes soluções ligadas à domótica, segurança, home cinema, som, imagem eletrónica e eletricidade.',
        stat1: 'Projetos concluídos',
        stat2: 'Prêmios ganhos',
        stat3: 'Clientes e parceiros'
      },
      vision: {
        title: 'A visão',
        description1: 'Em cada projeto e consequentemente em cada instalação, um extenso trabalho de consultoria, desenvolvimento e personalização.',
        description2:
          'O empenho, a eficiência técnica e a relação de confiança com os seus clientes, traduzem-se em trabalhos exclusivos e criativos, aliados à mais recente tecnologia.'
      },
      goal: {
        title: 'O objetivo',
        description1:
          'A F4Build é e será uma marca que se destacará  na linha da frente da excelência, da inovação, da qualidade de serviços, soluções personalizadas, competência e serviço pós venda.'
      },
      feat: {
        title: '“Cada projeto, cada cliente é tratado como único e exclusivo, respeitando sempre a sua individualidade!“',
        sub: 'CEO & Co-fundador'
      }
    },
    portfolio: {
      hero: {
        title: 'O nosso trabalho',
        description: 'Mergulhe nos nossos projetos e descubra o que podemos realizar para si.'
      }
    },
    contact: {
      title: 'Pronto para começar?',
      description1: 'Peça o seu orçamento hoje e transforme a sua visão em realidade com a nossa orientação especializada em cada fase do percurso.',
      description2: 'Transforme os seus sonhos em realidade.',
      description3: 'Criar para inovar',
      form: {
        name: 'Nome',
        email: 'Email',
        phone: 'Telefone',
        activity: 'Sector de atividade',
        country: 'País',
        footer: 'Ao solicitar, está a consentir que a F4Build processe a sua informação pessoal submetida acima.',
        button: 'enviar pedido',
        product: 'Produto que estou a procurar',
        infoProducts: {
          label1: 'Domótica',
          label2: 'Cinema em casa',
          label3: 'Som',
          label4: 'Segurança',
          label5: 'Redes Estruturadas',
          label6: 'Imagem',
          label7: "TV's em Espelhos",
          label8: "TV's premium",
          label9: 'Carregamento auto',
          label10: 'Colunas invisíveis',
          label11: 'Som e imagem para Exterior',
          label12: 'Som Multiroom',
          label13: 'Áudio profissional',
          label14: 'Soluções Premium para garagens',
          label15: 'Controlo de acessos',
          label16: 'Eletricidade',
          label17: 'Elevadores de Tv',
          label18: 'Controlo de Multimédia',
          label19: 'Outro'
        },
        error: {
          required1: 'Obrigatório',
          required2: 'Este campo deve ser preenchido.',
          email: 'Este não é um endereço de e-mail válido.',
          product: 'Tem de selecionar pelo menos um produto.'
        },
        sucessMessage: 'Entraremos em contacto em breve',
        errorMessage: 'Tente novamente'
      }
    },
    footer: {
      email: 'email',
      address: 'morada',
      contact: 'contacto',
      social: 'siga-nos',
      policy: 'Política de Privacidade',
      terms: 'Termos e Condições',
      politic: 'Política de Cookies',
      rights: 'Todos os direitos reservados.'
    },
    home: {
      title1: 'Soluções inteligentes para',
      title2: 'um mundo em evolução',
      subtitle: 'Vidas simplificadas',
      bold: 'Somos ousados',
      text1: 'Somos uma marca com destaque nacional e internacional no campo da inovação tecnológica.',
      text2: 'Nosso compromisso é simples: oferecer as soluções de vida inteligente mais recentes e exclusivas.',
      company: 'uma empresa',
      learnmore: 'saiba mais'
    },
    prestige: {
      title: 'Áreas de atuação',
      subtitle: 'Se sonha com tecnologia, iremos ajudar a tornar o sonho realidade.',
      learnMore: 'saiba mais',
      picture: 'Imagem',
      sound: 'Som',
      automation: 'Automação',
      network: 'Rede',
      electricity: 'Eletricidade',
      security: 'Segurança'
    },
    brands: {
      title: 'Parceiros com quem trabalhamos'
    },
    cinemaHome: {
      title: 'Cinema em casa, criamos soluções exclusivas',
      subtitle: 'Mergulhe numa experiência cinematográfica.',
      soundTitle: 'O Poder do Som',
      soundItem1: 'Som Dolby Atmos',
      soundItem2: 'Auro 3D',
      soundItem3: 'Controlo através de app',
      soundItem4: 'Projetos acústicos',
      soundItem5: 'Calibração certificada',
      soundItem6: 'Painéis acústicos',
      soundItem7: 'DTS:X',
      soundItem8: 'Controlo por voz',
      soundItem9: 'Salas inteligentes',
      pictureTitle: 'Emergindo numa Imagem',
      pictureItem1: 'Telas até 300"',
      pictureItem2: 'Light Design',
      pictureItem3: 'Mobiliário',
      pictureItem4: 'Telas 16:9,  4K',
      pictureItem5: 'Dolby Vision',
      pictureItem6: 'Fibra Ótica, Céu estrelado',
      pictureItem7: 'HDR10+',
      pictureItem8: 'Cenários personalizados',
      pictureItem9: 'Projetos CAD & 3D'
    },
    showroom: {
      title: 'Visite o nosso showroom e surpreenda-se',
      subtitle: 'Além de palavras, um lugar onde pode realmente vivenciar',
      projectorName: 'Projetor a laser',
      projectorDesc: '4K SXRD nativo',
      lightName: 'Iluminação',
      lightDesc: '3200 lumens ANSI',
      receiverName: 'Recetor AV',
      receiverDesc: '150 W (8 Ω, 20 Hz - 20 kHz) por canal',
      resolutionName: 'Resolução',
      resolutionDesc: '4096 x 2160 pixels',
      isolationName: 'Isolamento',
      isolationDesc: 'Tratamento acústico',
      speakersName: 'Altifalantes',
      speakersDesc: '150W 4 Ohms, sensibilidade 89dB',
      audioName: 'Áudio',
      audioDesc: '7.2.4 Dolby Atmos',
      decorName: 'Decoração',
      decorDesc: 'Mobiliário com design F4Build',
      extraName: 'Extra',
      extraDesc: 'Céu estrelado em fibra óptica'
    },
    tailoredHome: {
      title: 'Projetos exclusivos personalizados',
      garageTitle: 'Dream Garage',
      garageDesc:
        'Experimente conveniência com portas automáticas, sensores avançados e iluminação otimizada. Garagens que garantem segurança máxima e fácil acesso ao seu veículo exclusivo.',
      marineTitle: 'Marine',
      marineDesc: 'Torne seu iate ainda mais exclusivo e diferenciado, com soluções de segurança, iluminação, multimídia e controle.',
      collectionsTitle: 'Coleções Exclusivas',
      collectionsDesc:
        'Tecnologia de ponta das marcas mais exclusivas transforma equipamentos em peças de arte autênticas. Adicione design e qualidade ao seu espaço.',
      opticTitle: 'Fibra ótica',
      opticDesc: 'Piscinas e tetos com efeito de céu estrelado, uma alternativa à iluminação tradicional. Trazemos o céu para o seu teto ou piscina.'
    }
  }
};
