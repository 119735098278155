import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';

const SEO = () => {
  const {i18n} = useTranslation();
  const selectedLanguage = i18n.language;
  const isPt = selectedLanguage.includes('pt');

  const title = isPt
    ? 'F4Build by AR Group - Automação, Segurança e Soluções Inteligentes em Portugal.'
    : 'F4Build by AR Group - Home Automation, Security, and Smart Solutions in Portugal';

  const desc = isPt
    ? 'A F4Build oferece soluções inovadoras de domótica, segurança e tecnologia inteligente. Modernize a sua casa ou empresa com áudio e vídeo de qualidade de cinema, automação eficiente e sistemas de segurança de última geração.. Braga.'
    : 'F4Build offers cutting-edge home automation, security, and smart technology solutions. Upgrade your home or business with cinema-quality audio, video, energy-efficient automation, and state-of-the-art security systems. Braga.';

  const keywords = isPt
    ? 'domótica, casa inteligente, sistemas de segurança, sala de cinema, sistemas de áudio, tecnologia inteligente, Portugal, F4BUILD, eficiência energética, videovigilância, ar group, f4 build, braga'
    : 'home automation, smart home, security systems, cinema room, audio systems, smart technology, Portugal, F4BUILD, energy efficiency, video surveillance, ar group, f4 build, braga';

  const canonical = isPt ? 'https://f4build.pt/' : 'https://f4build.pt/en/';

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={desc} />
      <meta name="keywords" content={keywords} />
      <meta name="title" content={title} />
      <link rel="canonical" hrefLang={isPt ? 'pt' : 'en'} href={canonical} />
      <meta name="author" content="F4Build by AR Group" />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:url" content="https://f4build.pt/" />
      <meta property="og:site_name" content="AR Groupp" />
      <meta property="og:locale" content={isPt ? 'pt' : 'en'} />
      <meta property="og:image" content="https://f4build.pt/logo.jpg" />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content="F4Build" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:site" content="@f4-build" />
      <meta name="twitter:image" content="https://f4build.pt/logo.jpg" />
      {/* End Twitter tags */}
      {/* ld+json script  */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "F4Build",
            "url": "https://f4build.pt/",
            "logo": "https://f4build.pt/logo.jpg",
            "description": "${desc}",
            "image": ["https://f4build.pt/logo.jpg"],
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Rua do Castelo 41",
              "addressLocality": "Braga",
              "postalCode": "4700-311",
              "addressRegion": "Braga",
              "addressCountry": "PT"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+351926208399",
              "contactType": "Customer Service",
              "areaServed": "Global",
              "availableLanguage": ["English", "Portuguese"]
            },
            "sameAs": [
              "https://www.facebook.com/F4BUILD",
              "https://www.linkedin.com/company/f4build-",
              "https://www.instagram.com/f4build/"
            ],
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://f4build.pt/#website",
                "url": "https://f4build.pt/",
                "name": "F4BUILD",
                "inLanguage": "pt",
                "potentialAction": {
                  "@type": "SearchAction",
                  "target": "https://f4build.pt/?s={search_term_string}",
                  "query-input": "required name=search_term_string"
                }
              },
              {
                "@type": "LocalBusiness",
                "@id": "https://f4build.pt/#localbusiness",
                "name": "F4BUILD",
                "image": "https://f4build.pt/logo.jpg",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Rua do Castelo 41",
                  "addressLocality": "Braga",
                  "postalCode": "4700-311",
                  "addressRegion": "Braga",
                  "addressCountry": "PT"
                },
                "telephone": "+351926208399",
                "priceRange": "$$$",
                "openingHoursSpecification": {
                  "@type": "OpeningHoursSpecification",
                  "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                  "opens": "09:00",
                  "closes": "18:00"
                }
              }
            ]
          }
      `}
      </script>
    </Helmet>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default SEO;
