import {lazy} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

const Layout = lazy(() => import('@/components/ui/layout/layout'));
const AboutUs = lazy(() => import('@/pages/about-us'));
const Home = lazy(() => import('@/pages/home'));
const Portfolio = lazy(() => import('@/pages/portfolio'));
const Services = lazy(() => import('@/pages/services'));

import {PATHS, PATHS_EN} from './paths';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* PT routes */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={PATHS.services} element={<Services />} />
          <Route path={PATHS.aboutUs} element={<AboutUs />} />
          <Route path={PATHS.portfolio} element={<Portfolio />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
        {/* EN routes */}
        <Route path="/en" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={PATHS_EN.services} element={<Services />} />
          <Route path={PATHS_EN.aboutUs} element={<AboutUs />} />
          <Route path={PATHS_EN.portfolio} element={<Portfolio />} />
          <Route path="*" element={<Navigate to="/en" replace />} />
        </Route>
        {/* Fallback to PT / */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
