import {useEffect} from 'react';
import ReactGA from 'react-ga4';
import Router from './router/router';
import {HelmetProvider} from 'react-helmet-async';
import SEO from './components/ui/layout/seo';
import {disableReactDevTools} from './lib/disableReactDevTools';
import React from 'react';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const App = () => {
  // INIT ANALYTICS
  useEffect(() => {
    ReactGA.initialize('G-LG0HVW6HTJ');
  }, []);

  return (
    <HelmetProvider>
      <SEO />
      <React.Suspense fallback={<div className="bg-[#000000] h-screen h-svh w-screen w-svw" />}>
        <Router />
      </React.Suspense>
    </HelmetProvider>
  );
};

export default App;
