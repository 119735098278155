export default {
  translations: {
    navBar: {
      route1: 'Services',
      route2: 'About us',
      route3: 'Portfolio'
    },
    services: {
      hero: {
        title: 'All you need in one place',
        description1: 'We provide a full-service experience, from conception to implementation, ensuring excellence at every stage.',
        description2: 'All installation services are carried out by our experienced and dedicated i9atek team.',
        action: '‣ Learn more'
      },
      main: {
        title1: 'cinema',
        desc1: 'Exclusive solutions, where the alliance between design and technology results in exceptional rooms.',
        left1: ['4k & 8k Image', 'Acoustic correction'],
        right1: ['Dolby Atmos sound', 'Customized seats'],
        title2: 'security',
        desc2: 'The latest technology to help you protect what matters most.',
        left2: ['Video surveillance', 'Access control'],
        right2: ['Intrusion alarms', 'Safe and panic room'],
        title3: 'automation',
        desc3: 'Smart homes that provide comfort, savings, and well-being.',
        left3: ['Lighting control', 'Temperature control'],
        right3: ['Shades/curtain control', 'Integration with Multimedia and Security'],
        title4: 'sound',
        desc4: 'Indoor and outdoor audio that adds convenience with a simple touch on your tablet or phone.',
        left4: ['Multi-Room Sound', 'Immersive sound'],
        right4: ['High-fidelity', 'Invisible sound'],
        title5: 'networks',
        desc5: 'Connect devices inside and outside the home, making internet and resource sharing easier.',
        left5: ['Anti-Hacker Protection', 'Structured Network'],
        right5: ['Perfect Wi-Fi Coverage', 'Equipment integration'],
        title6: 'electricity',
        desc6: 'The foundation of everything, electrical installations designed for unique spaces.',
        left6: ['Electrical Projects', 'Certified installers'],
        right6: ['Home Automation Projects', 'Special installations']
      },
      craft: {
        title: 'Crafted from scratch',
        description1:
          'We meticulously designed and custom-made every project to your exact specifications, ensuring a unique and personalized creation just for you.',
        subTitle: 'A certified team',
        description2: 'We continually seek the finest technology to remain at the forefront of the industry.',
        label1: 'Contact',
        label2: 'Evaluation',
        label3: 'Development',
        label4: 'Proposal',
        label5: 'Acceptance',
        label6: 'Preparation',
        label7: 'Execution',
        label8: 'General Testing',
        label9: 'Follow-up'
      }
    },
    aboutUs: {
      hero: {
        title: 'Create to innovate',
        description: 'Our goal is to merge design and technology to create spaces where you feel truly at home.'
      },
      creation: {
        title: 'The principle',
        description1: 'F4build was created to become a national and international benchmark in the field of residential technological innovation.',
        description2:
          'Our commitment is to find the latest solutions in home automation, security, home cinema, sound, electronic imaging and electricity.',
        stat1: 'Completed projects',
        stat2: 'Prizes won',
        stat3: 'Clients & partners'
      },
      vision: {
        title: 'The vision',
        description1: 'Each project, and consequently each installation, involves extensive consultancy, development and customization work.',
        description2:
          'Commitment, technical efficiency and a relationship of trust with its clients translate into exclusive and creative work, combined with the latest technology.'
      },
      goal: {
        title: 'The goal',
        description1:
          'F4Build is and will be a brand that stands out at the forefront of excellence, innovation, quality of service, customized solutions, competence and after-sales service.'
      },
      feat: {
        title: '“Each project, each client is treated as unique and exclusive, always respecting their individuality!”',
        sub: 'CEO & Co-founder'
      }
    },
    portfolio: {
      hero: {
        title: 'Our craftsmanship',
        description: 'Immerse yourself in our projects and discover what we can achieve for you.'
      }
    },
    contact: {
      title: 'Ready to start?',
      description1: 'Request your quote today and transform your vision into reality with our expert guidance through each phase of the journey.',
      description2: 'Turn your dreams into reality.',
      description3: 'Create to innovate',
      form: {
        name: 'Name',
        email: 'Email',
        phone: 'Telephone',
        activity: 'Sector of activity',
        country: 'Country',
        footer: 'By requesting, you are consenting F4Build to process your personal information submitted above.',
        button: 'send request',
        product: "Product I'm looking for",
        infoProducts: {
          label1: 'Domotics',
          label2: 'Home cinema',
          label3: 'Sound',
          label4: 'Security',
          label5: 'Structured networks',
          label6: 'Image',
          label7: "TV's in Mirrors",
          label8: "Premium TV's",
          label9: 'Auto charging',
          label10: 'Invisible speakers',
          label11: 'Outdoor sound and image',
          label12: 'Multiroom Sound',
          label13: 'Professional audio',
          label14: 'Premium solutions for garages',
          label15: 'Access control',
          label16: 'Electricity',
          label17: 'TV elevators',
          label18: 'Multimedia Control',
          label19: 'Other'
        },
        error: {
          required1: 'Required',
          required2: 'This field must be filled in.',
          email: 'This is not a valid e-mail address.',
          product: 'You have to select at least one product.'
        },
        sucessMessage: "We'll contact you soon",
        errorMessage: 'Try again'
      }
    },
    footer: {
      email: 'email',
      address: 'address',
      contact: 'contact',
      social: 'social',
      policy: 'Privacy Policy',
      terms: 'Terms and conditions',
      politic: 'Cookie Policy',
      rights: 'All rights reserved.'
    },
    home: {
      title1: 'Smart solutions for',
      title2: 'a changing world',
      subtitle: 'Simplified lives',
      bold: 'We are bold',
      text1: 'We are a brand with national and international prominence in the field of technological innovation.',
      text2: 'Our commitment is simple: to offer the most recent and exclusive smart living solutions.',
      company: 'a company',
      learnmore: 'learn more'
    },
    prestige: {
      title: 'Areas of Expertise',
      subtitle: 'If you dream about it, we will bring it to life.',
      learnMore: 'learn more',
      picture: 'Picture',
      sound: 'Sound',
      automation: 'Automation',
      network: 'Network',
      electricity: 'Electricity',
      security: 'Security'
    },
    brands: {
      title: 'Brands we work with'
    },
    cinemaHome: {
      title: 'Home cinema, we create exclusive solutions',
      subtitle: 'Immerse yourself in a cinematic experience at home.',
      soundTitle: 'The Power of Sound',
      soundItem1: 'Sound Dolby Atmos',
      soundItem2: 'Auro 3D',
      soundItem3: 'App control',
      soundItem4: 'Acoustic projects',
      soundItem5: 'Certified calibration',
      soundItem6: 'Acoustic panels',
      soundItem7: 'DTS:X',
      soundItem8: 'Voice control',
      soundItem9: 'Smart rooms',
      pictureTitle: 'Emerging in a Picture',
      pictureItem1: 'Screens up to 300"',
      pictureItem2: 'Light design',
      pictureItem3: 'Furniture',
      pictureItem4: 'Screen 16:9, 4K',
      pictureItem5: 'Dolby Vision',
      pictureItem6: 'Optic Fiber, Starry Sky',
      pictureItem7: 'HDR10+',
      pictureItem8: 'Custom scenes',
      pictureItem9: 'CAD & 3D projects'
    },
    showroom: {
      title: 'Visit our showroom and be amazed',
      subtitle: 'Beyond words, a place where you can truly experience it.',
      projectorName: 'Laser projector',
      projectorDesc: 'Native 4K SXRD',
      lightName: 'Lighting',
      lightDesc: '3200 ANSI lumens',
      receiverName: 'AV Receiver',
      receiverDesc: '150 W (8 Ω, 20 Hz - 20 kHz) per channel',
      resolutionName: 'Resolution',
      resolutionDesc: '4096 x 2160 pixels',
      isolationName: 'Isolation',
      isolationDesc: 'Acoustic treatment',
      speakersName: 'Speakers',
      speakersDesc: '150W 4 Ohms, sensitivity 89dB',
      audioName: 'Audio',
      audioDesc: '7.2.4 Dolby Atmos',
      decorName: 'Decoration',
      decorDesc: 'Furniture with F4Build design',
      extraName: 'Extra',
      extraDesc: 'Starry skies in fibre optics'
    },
    tailoredHome: {
      title: 'A tailored creation for you',
      garageTitle: 'Dream Garage',
      garageDesc:
        'Experience convenience with automatic doors, advanced sensors, and optimized lighting. Our garage ensures top security and effortless access for your luxury vehicle.',
      marineTitle: 'Marine',
      marineDesc: 'Make your yacht even more exclusive and set it apart from the rest, with security, lighting, multimedia and control solutions.',
      collectionsTitle: 'Exclusive Collections',
      collectionsDesc:
        'Cutting-edge technology from the most exclusive brands transforms equipment into authentic pieces of art. Add design and quality to your space.',
      opticTitle: 'Optic fiber',
      opticDesc: 'Swimming pools with a starry sky effect, an alternative to traditional lighting. We bring the sky to your pool.'
    }
  }
};
